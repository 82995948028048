/**
 * CONTENTS
 *
 * 1 - SETTINGS
 * Color-Reference............A theme-specific list of all available colors.
 *
 * 2 - BASE
 * Headings...................H1–H6 styles for generic versions of those elements.
 *
 * 3 - OBJECTS
 * buttons....................All button styles.
 * icons......................Reusable icons.
 *
 * 4 - COMPONENTS
 * header-top.................Fixed header toolbar.
 * site-menu..................Main site navigation.
 * site-footer................Main site footer.
 * product-filters............Filters for product lists.
 * product-bar................Utility bar for product lists.
 * pagination.................Product list pagination.
 * prod-options-related.......Related products and product option styles on product details pages.
 * product-details............Product detail page styles.
 * product-info...............Product info styles on quick view and product details pages.
 * checkout...................Checkout pages.
 * account-nav................Navigation for My Account section.
 * shopping-cart..............Shopping Cart styles.
 * home-page..................Home page styles.
 * home-articles..............Article list for home page.
 * tabs.......................Tabs.
 *
 * 5 - OVERRIDES
 *
 *
 */

/**
 * 1 - SETTINGS
 */

/* ---------------------------
    #Color-reference
--------------------------- */

/**
 *
 * Primary: 		#ae256f | rgb(234,  25, 141);
 * Primary (Dark): 	#7e0144 | rgb(126,   1,  68);
 * Secondary: 		#9dbb38 | rgb(157, 187,  56);
 *
 * Black: 			#000000 | rgb(  0,   0,   0);
 * 					#333333 | rgb( 51,  51,  51);
 * 					#363636 | rgb( 54,  54,  54);
 * 					#6d6e71 | rgb(109, 110, 113);
 * 					#7e7e7e | rgb(126, 126, 126);
 * 					#d1d2d4 | rgb(209, 210, 212);
 * 					#d6d6d6 | rgb(214, 214, 214);
 * 					#f4f4f4 | rgb(244, 244, 244);
 * White: 			#ffffff | rgb(255, 255, 255);
 *
 */


 /**
  * 2 - BASE
  */

 /* ---------------------------
     #headings
 --------------------------- */

 h1, h1 a {
 	color: #ae256f;
 }


 /**
  * 3 - OBJECTS
  */

 /* ---------------------------
     #buttons
 --------------------------- */

.button {
	color: #fff;
	background: #ae256f;
}

.RadGrid_Silk .rgEditForm .button {
	color: #fff;
}

.button:hover {
	background: #7e0144;
}

.button--line {
	color: #7e7e7e;
	background: #fff;
}

.button--line:hover {
	color: #7e7e7e;
	background: #eee;
}

.button--text {
	color: #005ca0;
	background: none;
	border: none;
}

.button--text:hover {
	color: #005ca0;
	background: none;
	text-decoration: underline;
}

.button--quickview {
	background: #ae256f;
}

.button--pending {
	&::after {
		background-color: #ae256f;
	}
}


/* ---------------------------
 #icons
--------------------------- */

.i--twitter, .i--facebook, .i--linkedin, .i--pinterest, .i--youtube, .i--wordpress, .i--feed, .i--instagram {
	background-color: #ae256f;
}

.i--twitter-hover 	{ background-color: #3dc7f4 !important; }
.i--facebook-hover	{ background-color: #3b5998 !important; }
.i--linkedin-hover 	{ background-color: #00659b; }
.i--pinterest-hover { background-color: #dc3e47 !important; }
.i--youtube-hover 	{ background-color: #e40202 !important; }
.i--wordpress-hover { background-color: #247ca4; }
.i--feed-hover 		{ background-color: #e08e00; }
.i--instagram-hover { background-color: #773eb1 !important; }


/**
 * 4 - COMPONENTS
 */

/* ---------------------------
    #header-top
--------------------------- */

.header-top {
	color: #fff;
	background-color: #363636;
}

.header-top--added {
	background-color: #338326;
}

.header-top__link > a {
	color: #fff;
}


/* ---------------------------
    #site-menu
--------------------------- */

.site-nav {
	background-color: #d6d6d6;
	border-top: none;
	border-bottom: none;
}

.sub-nav {
	background: #f4f4f4;
	border-top: none;
}

@media only screen and (min-width: 820px) {
	.site-nav {
		padding: 0;
		background-color: #fff;
		border-bottom: 1px solid #6d6e71;
	}

	.site-nav__top {
		margin: 0;
		border-radius: 0;
	}

	.site-nav__item:hover .site-nav__top, .faux-hover .site-nav__top {
		color: #fff;
		background: #FAB5B6;
	}

	.sub-nav {
		margin-top: 0;
		padding: 0 1px 1px 10px;
		background: #FAB5B6;
		border-top: 1px solid #6d6e71;
		border-radius: 0;
	}

	.sub-nav__inner {
		border-radius: 0;
	}
}

.site-nav__item--black-friday .site-nav__top, .site-nav__item--black-friday:hover .site-nav__top {
	color: #000;
	background-color: #ec0b8e;
}


/* ---------------------------
    #site-footer
--------------------------- */

.site-footer {
	max-width: none;
	margin-top: 45px;
	color: #d1d2d4;
	background-color: #363636;
}

.footer-inner {
	max-width: 1000px;
	margin: 0 auto;
}

.site-footer__header {
	border-bottom: none;
}

.site-footer__copyright {
	color: #d1d2d4;
	border-top: 1px solid #6d6e71;
}

.site-footer a {
	color: #d1d2d4;
}


/* ---------------------------
    #product-filters
--------------------------- */

.filters__header {
	padding: 10px;
	font-size: 0.9em;
	background-color: #ae256f;
}



/* ---------------------------
    #product-bar
--------------------------- */

.product-bar {
	background-color: #d6d6d6;
}


/* ---------------------------
    #pagination
--------------------------- */

.pagination__link--active, .pagination__link--active:hover {
	color: #fff;
	background: #ae256f;
}


/* ---------------------------
    #prod-options-related
--------------------------- */

.prod-option:hover, .related-prod:hover {
	border-color: #ae256f;
}


/* ---------------------------
    #product-info
--------------------------- */

.prod-info__name {
	color: #ae256f;
}


/* ---------------------------
    #product-details
--------------------------- */

.product-thumb--selected, .product-thumb--selected:hover {
	border: 1px solid #ae256f;
}


/* ---------------------------
    #checkout
--------------------------- */

.checkout-progress-bar {
	background-color: #d6d6d6;
}

.checkout-progress-selected {
	color: #363636;
}

.checkout-progress {
	color: #6d6e71;
}


/* ---------------------------
    #account-nav
--------------------------- */

.account-nav__list {
	border-top-color: #ae256f;
}


/* ---------------------------
    #shopping-cart
--------------------------- */

.cart-tbl th {
	background-color: #d6d6d6;
	color: #333;
}


/* ---------------------------
    #home-page
--------------------------- */

.slider-caption {
	color: #6d6e71;
	font-size: 1em;
}

.bx-wrapper .bx-pager.bx-default-pager a {
	background: #d1d2d4;
}

.bx-wrapper .bx-controls-direction a {
	width: 31px;
	height: 31px;
}

.bx-wrapper .bx-next {
	background: url('/content/themes/thestylegarage/images/slider-arrows.png') no-repeat -31px 0;
}

.bx-wrapper .bx-prev {
	background: url('/content/themes/thestylegarage/images/slider-arrows.png') no-repeat 0 0;
}

.bx-wrapper .bx-prev:hover {
	background-position: 0 0;
}

.bx-wrapper .bx-next:hover {
	background-position: -31px 0;
}

.main-banner-wrap .bx-controls-direction, .main-banner-wrap .bx-wrapper .bx-next, .main-banner-wrap .bx-wrapper .bx-prev {
	display: block;
}

.main-banner-wrap .bx-wrapper .bx-next {
	right: 8px;
}

.main-banner-wrap .bx-wrapper .bx-prev {
	left: 8px;
}

.bar {
	padding: 0;
	color: #6d6e71;
	font-size: 18px;
	text-transform: uppercase;
	background: none;
}

.bar span {
	color: #ae256f;
	text-transform: none;
}


/* ---------------------------
    #home-articles
--------------------------- */

.home-articles {
	margin-bottom: 60px;
}

.home-article__title {
	color: #ae256f;
}

.home-articles__more {
	color: #9dbb38;
}

.fb-feed {
	margin-top: 30px;
}


/* ---------------------------
    #tabs
--------------------------- */

.tabs-nav .tabs-nav--active {
	background: #ae256f;
}




.catalog--primary .catalog__text {
	color: #ae256f;
}

.catalog--secondary .catalog__text {
	color: #9dbb38;
}

.catalog--primary .catalog__text::after {
	background-image: url('/content/themes/thestylegarage/images/arrow-pink.png');
}

.catalog--secondary .catalog__text::after {
	background-image: url('/content/themes/thestylegarage/images/arrow-green.png');
}



/* ---------------------------
    #pecil-banner
--------------------------- */

.page--default .pencil-banner__image {
    display: none;
}

@import "redesign/theme";
